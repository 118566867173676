import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "C:/Users/sezer/Documents/Projects/talepnet-landing/node_modules/gatsby-theme-docz/src/base/Layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2 {...{
      "id": "talepnet-satın-alma-yönetimi-dokümantasyon"
    }}>{`TalepNET Satın Alma Yönetimi Dokümantasyon`}</h2>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/documentation/getting-started"
        }}>{`TalepNET Hakkında`}</a>
        <ul parentName="li">
          <li parentName="ul"><a parentName="li" {...{
              "href": "/documentation/getting-started#talepnet"
            }}>{`TalepNET`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/documentation/getting-started#talepnet-portal"
            }}>{`TalepNET Portal`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/documentation/getting-started#ucretsiz-baslayin"
            }}>{`Ücretsiz Başlayın`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/documentation/getting-started#fiyatlandirma"
            }}>{`Fiyatlandırma`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/documentation/getting-started#giris-yapin"
            }}>{`Giriş Yapın`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/documentation/getting-started#parolanizi-unutursaniz"
            }}>{`Parolanızı Unutursanız`}</a></li>
        </ul>
      </li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/documentation/settings"
        }}>{`Ayarlar`}</a>
        <ul parentName="li">
          <li parentName="ul"><a parentName="li" {...{
              "href": "/documentation/settings#hesap-ayarlari"
            }}>{`Hesap Ayarları`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/documentation/settings#firma"
            }}>{`Firma`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/documentation/settings#organizasyon-semasi"
            }}>{`Organizasyon Şeması`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/documentation/settings#subeler"
            }}>{`Şubeler`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/documentation/settings#kullanicilar"
            }}>{`Kullanıcılar`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/documentation/settings#proses"
            }}>{`Proses`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/documentation/settings#satin-alma-yonetimi-proses-adimlari"
            }}>{`Satın Alma Yönetimi Proses Adımları`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/documentation/settings#parametreler"
            }}>{`Parametreler`}</a></li>
        </ul>
      </li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/documentation/definitions"
        }}>{`Modül Tanımları`}</a>
        <ul parentName="li">
          <li parentName="ul"><a parentName="li" {...{
              "href": "/documentation/definitions#envanter-yonetimi"
            }}>{`Envanter Yönetimi`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/documentation/definitions#birim-kodlari"
            }}>{`Birim Kodları`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/documentation/definitions#urun-gruplari"
            }}>{`Ürün Grupları`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/documentation/definitions#urunler"
            }}>{`Ürünler`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/documentation/definitions#satin-alma-yonetimi"
            }}>{`Satın Alma Yönetimi`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/documentation/definitions#tedarikciler"
            }}>{`Tedarikçiler`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/documentation/definitions#satin-alma-anlasmalari"
            }}>{`Satın Alma Anlaşmaları`}</a></li>
        </ul>
      </li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/documentation/console"
        }}>{`Konsol`}</a>
        <ul parentName="li">
          <li parentName="ul"><a parentName="li" {...{
              "href": "/documentation/console#taleplerim"
            }}>{`Taleplerim`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/documentation/console#depo"
            }}>{`Depo`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/documentation/console#talep-onaylari"
            }}>{`Talep Onayları`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/documentation/console#satin-alma-onaylari"
            }}>{`Satın Alma Onayları`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/documentation/console#satin-alma"
            }}>{`Satın Alma`}</a></li>
        </ul>
      </li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      